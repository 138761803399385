<template>
  <v-container
    fluid
    tag="section"
  >
    <validation-observer v-slot="{ valid }">
      <material-wizard
        title="Salvar Setorização"
        subtitle="Selecione uma área de concessão e divida-a com base em uma regra de setorização."
        v-model="tab"
        :available-steps="availableSteps"
        :tabs="tabs"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
        :loading="loading"
        :disabledBtnFinalizar="disabledBtnFinalizar"
      >
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <first-tab-content
              ref="FirstTabContent"
              @data:updated="handleTabContentUpdated"
              :items="setorizacao"
            />
          </form>
        </v-tab-item>
        <v-tab-item class="pb-1">
          <second-tab-content
            :month="setorizacao.data"
            :bdgdVersion="setorizacao.bdgdVersion"
            @data:updated="handleTabContentUpdated"
            :items="setorizacao"
          />
        </v-tab-item>
      </material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import SetorizacoesService from '@/services/SetorizacoesService';
import tourMixins from '@/mixins/tourMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [tourMixins],
  components: {
    MaterialWizard: () => import('@/components/base/MaterialWizard'),
    FirstTabContent: () =>
      import('@/components/setorizacoes/tabs/FirstTabContent.vue'),
    SecondTabContent: () =>
      import('@/components/setorizacoes/tabs/SecondTabContent.vue')
  },
  data: () => ({
    tab: null,
    tabs: ['Informações básicas', 'Regra de setorização'],
    loading: false,
    setorizacao: {
      id: null,
      mes: null,
      bdgdVersion: null,
      aratFeatures: 0,
      algoritmo: null,
      algoritmoConfig: {}
    },
    arrTourPrimeiraTab: [
      {
        element: '#fs-nome-setorizacao-bdgd',
        intro: 'Aqui você deverá inserir um nome para sua setorização.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#setorizacao-bdgd-versao',
        intro: 'Você deve selecionar o mês e a versão da BDGD.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#setorizacao-mapa',
        intro:
          'Aqui você irá visualizar a área de concessão da empresa, caso exista uma versão importada (entidade ARAT).',
        scrollTo: 'tooltip',
        position: 'bottom'
      }
    ],
    arrTourSegundaTab: [
      {
        element: '#algoritmo-setorizacao',
        intro:
          'Aqui você deverá selecionar e configurar os parâmetros da regra de setorização, ou seja, o algoritmo que irá quebrar a área de concessão em setores para análises.',
        scrollTo: 'tooltip',
        position: 'bottom'
      }
    ]
  }),
  mounted() {
    this.iniciarTourPrimeiraTab();
    if (this.$route.params.id) this.carregarSetorizacao(this.$route.params.id);
  },
  computed: {
    availableSteps() {
      let steps = [];

      if (this.tab1IsValid) {
        steps.push(0);
        steps.push(1);
      }

      if (this.tab2IsValid) {
        steps.push(2);
      }

      return steps;
    },

    tab1IsValid() {
      const { nome, mes, bdgdVersion, aratFeatures } = this.setorizacao;

      return (
        nome !== null &&
        mes !== null &&
        bdgdVersion !== null &&
        aratFeatures > 0
      );
    },

    tab2IsValid() {
      const { algoritmo, algoritmoConfig } = this.setorizacao;

      return (
        this.tab1IsValid &&
        algoritmo &&
        algoritmo.id &&
        algoritmoConfig &&
        this.algoritmoConfigIsValid
      );
    },

    algoritmoConfigIsValid() {
      const { algoritmo, algoritmoConfig } = this.setorizacao;

      if (!algoritmo) return false;

      switch (algoritmo.classe) {
        case 'ConjuntoEletricoBdgd':
          return algoritmoConfig && algoritmoConfig.numConjuntos > 1;
        case 'MunicipioIbge':
          return algoritmoConfig && algoritmoConfig.numMunicipios > 1;
        case 'QuebrarAratBdgd':
          return (
            algoritmoConfig &&
            algoritmoConfig.xPartes &&
            algoritmoConfig.xPartes >= algoritmoConfig.min
          );
        case 'DesenharPoligonos':
          return (
            algoritmoConfig &&
            algoritmoConfig.poligonos &&
            Object.keys(algoritmoConfig.poligonos).length > 0
          );
        default:
          return false;
      }
    },

    disabledBtnFinalizar() {
      if (this.tab === 0) return !this.tab1IsValid;
      if (this.tab === 1) return !this.tab2IsValid;
      return true;
    }
  },
  methods: {
    async next(valid) {
      if (!valid) return;

      if (this.tab === 0 && !this.tab1IsValid) {
        return;
      }

      if (this.tab === 1) {
        if (!this.tab2IsValid) {
          return;
        }

        const sucesso = await this.salvarSetorizacao();
        if (!sucesso) {
          return;
        }

        this.$router.push({
          name: routes.state.appRoutes['SETORIZACOES_HISTORICO']
        });
      } else {
        this.tab++;
      }
    },
    handleTabContentUpdated(event) {
      this.setorizacao = Object.assign(this.setorizacao, event);
    },
    async salvarSetorizacao() {
      let sucesso = false;
      this.loading = true;

      try {
        let data = {
          ...this.setorizacao,
          id: this.$route.params.id ? this.$route.params.id : null,
          setorizacao_algoritmo_config: JSON.stringify(
            this.setorizacao.algoritmoConfig
          ),
          setorizacao_algoritmo_id: this.setorizacao.algoritmo.id,
          data_registro: this.setorizacao.data,
          versao: this.setorizacao.bdgdVersion
        };

        sucesso = await SetorizacoesService.save(data);

        this.$toast.success('Setorização salva com sucesso.', '', {
          position: 'topRight'
        });
      } catch (e) {
        this.$toast.error('Erro ao salvar a setorização.', '', {
          position: 'topRight'
        });
        console.log(e);
      } finally {
        this.loading = false;
      }

      return sucesso;
    },
    iniciarTourPrimeiraTab() {
      this.doneLabel = 'Próximo';
      this.desativarTourAoSair = false;

      let onBeforeExitFunction = {
        func: () => {
          this.tab = 1;
          this.initTourSegundaTab();
        }
      };

      this.iniciarTour(this.arrTourPrimeiraTab, [], [], [onBeforeExitFunction]);
    },
    initTourSegundaTab() {
      this.doneLabel = 'Finalizar';
      this.desativarTourAoSair = true;

      let onBeforeExitFunction = {
        func: () => (this.tab = 0)
      };

      this.iniciarTour(this.arrTourSegundaTab, [], [], [onBeforeExitFunction]);
    },
    carregarSetorizacao(id) {
      this.loading = true;

      SetorizacoesService.getSetorizacao(id)
        .then((response) => {
          const setorizacaoData = response.data.data;

          this.setorizacao = {
            id: setorizacaoData.id,
            nome: setorizacaoData.nome,
            data: setorizacaoData.data,
            mes: setorizacaoData.mes,
            bdgdVersion: setorizacaoData.versao,
            aratFeatures: setorizacaoData.aratFeatures,
            algoritmo: setorizacaoData.algoritmo,
            algoritmo_config: JSON.parse(
              setorizacaoData.setorizacao_algoritmo_config
            )
          };
        })
        .catch((e) => {
          this.$toast.error('Erro ao carregar os dados da setorização.', '', {
            position: 'topRight'
          });
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
          if (this.$refs.FirstTabContent)
            this.$refs.FirstTabContent.fillInputs();
        });
    }
  }
};
</script>
